import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Volunteering at CoderDojo",
  "date": "2022-01-01",
  "author": "Andy B",
  "featuredImage": "Designer-62.jpeg"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Interested in volunteering with Bishops Cleeve CoderDojo?  Thank you!  This page tells you all you need to know.`}</p>
    <h2>{`What is Bishops Cleeve CoderDojo?`}</h2>
    <p>{`Bishops Cleeve CoderDojo is a member of the global Code Club movement that inspires young people to get excited about computing and digital making and develops their confidence to create with digital technologies.`}</p>
    <p>{`We are a part of the Raspberry Pi Foundation, an educational charity with a global mission to help young people realise their full potential through the power of computing and digital technologies.`}</p>
    <h2>{`How CoderDojo works`}</h2>
    <p>{`CoderDojo provides free coding projects with step-by-step instructions. The projects cover programming languages such as Scratch, HTML/CSS, and Python.`}</p>
    <p>{`CoderDojo members use our projects to have fun creating games, animations, and websites while they learn key programming concepts. CoderDojo also helps the young creators develop vital life skills such as collaboration, resilience, creativity, and problem-solving.`}</p>
    <h2>{`How you can help`}</h2>
    <p>{`The best way to get involved is to volunteer as a mentor to support the children during club sessions.  Don’t worry if you have no coding experience, as all our project instructions are beginner-friendly. You just need to be enthusiastic about guiding young people to solve problems and willing to learn about coding.`}</p>
    <p>{`The sessions run every other week at the Bishops Cleeve Library, from 1.00pm to 2.30pm (with 15 mins at the end of the session to help clear away).  Volunteers don't need to attend every session, you can come along as often or as little as suits you.`}</p>
    <p>{`We use WhatsApp to organise who will be helping out at each session, so you will need to provide a mobile number we can add to our group.`}</p>
    <p>{`All mentors need to be DBS checked.  We ask volunteers to sign up to the STEM Amabassodors scheme, who will provide this check free of charge.`}</p>
    <h2>{`How you benefit`}</h2>
    <ul>
      <li parentName="ul">{`You will have access to a wide range of valuable resources and support offered by the Raspberry Pi Foundation.`}</li>
      <li parentName="ul">{`You can build your coding and programming skills through free online Scratch, Python, and web design and development courses.`}</li>
      <li parentName="ul">{`You will join a global community of mentors who help young people grow their coding literacy.`}</li>
      <li parentName="ul">{`It's great fun, and really rewarding to watch the children build cool things!`}</li>
    </ul>
    <h2>{`Applying to Volunteer`}</h2>
    <p>{`If you'd like to get involved, please visit our club's page on the `}<a parentName="p" {...{
        "href": "https://codeclub.org/en/clubs/b05ca2dc-65c8-4501-bf67-869acc1773c9"
      }}>{`Code Club platform`}</a>{` and click the `}<strong parentName="p">{`Volunteer at this club`}</strong>{` button.`}</p>
    <p>{`We'll then invite you to join us for part of an upcoming session, to give you the opportunity to see how the club runs for yourself, and ask any questions.`}</p>
    <p>{`Following this, we'll ask you to read through some training information and join the STEM Abmassadors scheme, so we can sign you up as an official volunteer!`}</p>
    <p>{`If you have any questions, please do get in touch via `}<a parentName="p" {...{
        "href": "mailto:bishopscleeve.uk@coderdojo.com"
      }}>{`email`}</a></p>
    <h2>{`Resources`}</h2>
    <p>{`Here's a list of useful links and resources for club mentors:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://codeclub.org/en/run-a-club"
        }}>{`The Code Club guide to runnning a club`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://codeclub.org/en/resources"
        }}>{`Further Code Club resources`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://assets.ctfassets.net/zsyyd4yzh6xx/2pJzjgwtEwYwZ5L6MUPnI5/5b63d4fa7211cfc1b91f746f5536846e/Code_Club_Charter.pdf"
        }}>{`The Code Club Charter`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://codeclub.org/en/slack"
        }}>{`The Code Club Slack Channel`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://assets.ctfassets.net/zsyyd4yzh6xx/5DU80tjidPoa6L0Tlq6uiY/4c02613c80e81aa276c5d8ab6f2a3ad0/Code_Club_Mentor_Code_of_Behaviour_A4_V5_Digital.pdf"
        }}>{`Mentor code of behaviour`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      